import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-handshake-o fa-2x"></span>
    </div>
    <div className="content">
      <div className="inner">
        
        <h3> ГАРАНТ РОЗРАХУНКІВ </h3>
        
        <h1> TRUSTA</h1>
        <p>
          
         <strong> Онлайн сервіс безпечних завдатків №1 </strong> 
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Про сервіс
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Умови
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Про Нас
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Зв&#39;язатись
          </button>
        </li>
      </ul>
    </nav>
    <div className="content">
      <div className="inner">
      <div >
        <a className="button"  href={`https://t.me/${process.env.GATSBY_TELEGRAM_BOT}`}><i className="fa fa-telegram"></i> Розпочати </a>
    </div>
      </div>
    </div>
   

  </header> 
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
