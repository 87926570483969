import PropTypes from 'prop-types'
import React from 'react'
// import Offerta from './Offerta'
// import pic01 from '../images/pic01.jpg'
// import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'
import pumbLogo from "../images/pumb.png"
import visaLogo from "../images/Visa-logo.png"
import masterLogo from "../images/mc_symbol.svg"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Про Сервіс</h2>
          <span className="image main">
            {/* <img src={pic01} alt="" /> */}
          </span>
          <h3>TRUSTA - ОНЛАЙН-СЕРВІС БЕЗПЕЧНИХ ЗАВДАТКІВ №1.</h3>
          <p>
            TRUSTA забезпечує роботу системи peer-to-peer арбітражу виконання домовленостей між людьми, яка робить безпечним процес внесення та отримання завдатків або авансів за майбутню послугу або роботу.
          </p>

          <p>
            Сервіс дозволяє вносити та зберігати суму завдатку або авансу на спеціальному банківському рахунку, де кошти “заморожуються” до моменту виконання домовленості між сторонами. Якщо ж домовленість не буде виконано, тоді кошти повернуться відправнику. Виконання або скасування домовленостей підтверджується сторонами з використанням peer-to-peer алгоритму. 
          </p>
          
          <p>
            Обробку процесів списання, зберігання та зарахування коштів в рамках сервісу здійснює банк-партнер <a href="https://pumb.ua" title="PUMB PARTNER" target="_blank">ПУМБ (ПЕРШИЙ УКРАЇНСЬКИЙ МІЖНАРОДНИЙ БАНК)</a>.
          </p>

          <p>
            Сервіс працює відповідно до вимог законодавства України, правил Національного банку України та правил Міжнародних платіжних систем VISA та MasterCard:
            <ul>
              <li>
                до 10 операцій зарахування коштів на кожну окрему банківську платіжну картку щодоби;
              </li>
              <li>
                ліміт суми однієї транзакції становить 29 999,99 грн.;
              </li>
              <li>
                добовий ліміт суми зарахування коштів на кожну окрему банківську платіжну картку становить 89 999,97 грн.;
              </li>
              <li>
                місячний ліміт суми зарахування коштів на кожну окрему банківську платіжну картку становить 399 999,99 грн.;
              </li>
              <li>
                тижневий ліміт суми зарахування коштів на кожну окрему банківську платіжну картку становить 399 999,99 грн.
              </li>
            </ul>
          </p>

          <p>
            Комісія сервісу за peer-to-peer арбітраж та переведення коштів з картки на картку становить 5% від суми переказу (авансу), але мінімум 5,00 грн. Комісія утримується в момент списання коштів з банківської платіжної картки та не підлягає поверненню. 
            <br/>
            Ваш банк-емітент платіжної картки може утримувати стандартну комісію відповідно до ваших умов користування карткою.
          </p>
          <p>
            Термін знаходження суми завдатку (авансу) на зберіганні сервісу - до 120 днів. Якщо протягом цього часу жодна зі сторін не ініціює завершення угоди через засоби сервісу, то кошти будуть автоматично повернуті на рахунок відправника (за вирахуванням суми комісії сервісу).
          </p>

            {/* <h3>Сервіс TRUSTA будує довіру між людьми, гарантуючи цінність домовленостей</h3>
          <p>
          За допомогою нашого сервісу безпечних завдатків онлайн, клієнт і виконавець гарантують виконання домовленостей без ризику втратити гроші.
          </p> 

          <h4> Як же працює алгоритм TRUSTA? </h4>
          
          <p>
            Крок 1. Виконавець (постачальник) виставляє клієнту рахунок на сплату завдатку (авансу) в сервісі TRUSTA.
          </p>
          <p>
            Крок 2. Клієнт отримує рахунок на сплату завдатку (авансу) і сплачує його.
          </p>
          <p>
            Крок 3. Виконавець отримує повідомлення-підтвердження про те, що клієнт вніс завдаток.
          </p>
          <p>
          Крок 4. Завдаток зберігається на спеціальному банківському рахунку сервісу безпечних завдатків і не може бути отриманий жодною зі сторін, допоки вони не підтвердять отримання товару/послуги або ж скасування угоди.
          </p>
          <p>
          Крок 5.1. Якщо виконавець виконав зобов’язання за угодою, то клієнт підтверджує таке виконання в сервісі і сума завдатку (авансу) зараховується на рахнок виконавця.
          </p>
          <p>
          Крок 5.2. Якщо виконавець не може виконати угоду, то він скасовує угоду в сервісі і сума завдатку повертається на рахунок клієнта.
          </p>
          <p>
          У випадках, якщо інша сторона не підтверджує проведення або повернення коштів, сервіс призупиняє для сторін можливість створювати нові завдатки до того часу, поки сторони не вирішать спірні питання.
          </p>
          <p>
          Сервіс TRUSTA не здійснює розв’язання спірних питань, але гарантує збереження суми авансу на час, поки сторони не досягнуть згоди за результатом виконання угоди і не підтвердять переказ або повернення суми завдатку (авансу).
          </p>
          <p>
            Таким чином, сервіс TRUSTA гарантує цінність домовленостей.
          </p>
          <p>
            Користуючись сервісом безпечних завдатків онлайн, сторони отримують гарантію, що виконавець не зникне, отримавши завдаток (аванс), а клієнт (замовник) не замовлятиме товар/послугу/резервування/роботу, не маючи наміру або можливості за них заплатити.
          </p> */}
          <p>
             <a href="https://t.me/Zavdatok_bot" > Офіційний аванс-бот TRUSTA:  </a>
          </p>


          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Умови </h2>
          <span className="image main">
            {/* <img src={pic02} alt="" /> */}
          </span>
          <p>
          <a href="/privacy" title="Згода суб’єкта персональних даних">
            Згода суб’єкта персональних даних
          </a>
          </p>
          <p>
          <a href="/offerta/" title='Публічна оферта ТОВ "ТРАСТА"'>
            Публічна оферта ТОВ "ТРАСТА"
          </a>
          </p>
          <p>
          <a href="https://fuib-prod.payhub.com.ua/iframes/Public_offer_P2P_2017.pdf" title='Публічна оферта АТ “Перший Український Міжнародний Банк”'>
            Публічна оферта АТ “Перший Український Міжнародний Банк”
          </a>
          </p>
          <p>
            Термін дії послуги - до 120 днів. Якщо протягом 120 днів сторона-отримувач не підтвердить виконання або скасування угоди в інтерфейсі користувача через peer-to-peer алгоритм, то кошти будуть повернуті на рахунок відправника. Сума комісії сервісу при цьому не повертається.
          </p>
          <p>
            Комісія сервісу - 5% від суми завдатку/авансу, але мінімум - 5 гривень. Ваш банк-емітент платіжної картки може утримувати стандартну комісію відповідно до ваших умов користування карткою.
          </p>
          <p>
            Валюта транзакцій - українська гривня.
          </p>
          <p>
            Транзакції здійснюються між рахунками фізичних осіб з використанням технології чат-ботів.
          </p>
          {/* <p>
            Сервіс безпечних завдатків TRUSTA не збирає та не зберігає платіжних даних наших клієнтів. Всі дані, необхідні для проведення платежів, вводяться на стороні банків-партнерів і захищені відповідно до стандартних вимог міжнародних платіжних систем Visa та MasterCard.
          </p>
          <p>
            Умови опрацювання платежів через сервіс TRUSTA здійснюються у відповідності до чинного законодавства України та правил надання послуг міжнародних платіжних систем.
          </p>
          <p>
            Сервіс дозволяє користувачам формувати авторизовані запити до банків-партнерів на здійснення операцій списання та зарахування коштів з карти/на карту.
          </p>
          <p>
            Сервіс дозволяє оплачувати завдаток (аванс) з використанням банківських карток, випущеними будь-яким банком України.
          </p>
          <p>
            Сервіс дозволяє оплачувати завдаток (аванс) в національній валюті України.
          </p>
          <p>
            Час зберігання коштів на спеціальному банківському рахунку сервісу безпечних завдатків становить 150 днів.
          </p>
          <p>
            Під спеціальним банківським рахунком сервісу безпечних завдатків розуміється транзитний рахунок банка-партнера, який надає клієнтам банківську послугу.
          </p>
          <p>
            На суму завдатку (авансу) при користуванні сервісом TRUSTA нараховується комісія сервісу в розмірі 5%.
          </p>
          <p>
            Мінімальний розмір комісії сервісу - 5 гривень.
          </p> */}
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
          <h2 className="major">Про нас</h2>
          <span className="image main">
            {/* <img src={pic02} alt="" /> */}
          </span>
          <p>
            БУДУЄМО ДОВІРУ, ГАРАНТУЮЧИ ЦІННІСТЬ ДОМОВЛЕНОСТЕЙ
          </p>
          <p>
          В житті кожної людини бувають ситуації, коли вона про щось домовляється з іншою. І для гарантування виконання домовленості люди часто використовують таке поняття, як завдаток. Або - аванс. Сторона, яка має отримати товар або послугу в майбутньому, передає стороні, яка має ці товар або послугу надати, повну або часткову суму коштів в рахунок майбутньої оплати. І в таких ситуаціях, якщо завдаток/аванс будуть надані, завжди є ризик того, що інша сторона може зникнути, не виконавши свої зобов’язання. З іншої сторони, коли завдаток/аванс не надаються, завжди є ризик, що після отримання товару або послуги за них можуть не розрахуватися.
          </p>
          <p>
          Але ж кожна людина, коли про щось домовляється з іншою, хоче бути впевнена, що її не обмануть.
          </p>
          <p>
          <strong> Онлайн-сервіс безпечних завдатків дозволяє сторонам бути впевненими, що умови домовленостей між ними буде виконано. </strong> Алгоритими сервісу створюють для сторін умови, коли невиконання умов домовленостей не має для них сенсу - платник завдатку/авансу не ризикує втратити кошти не отримавши натомість товар або послугу, а інша сторона отримує впевненість, що за надані товари і послуги з нею розрахуються. Це забезпечується за рахунок розвитку рішень на основі алгоритмів peer-to-peer-арбітражу, які дають можливість сторонам діяти на принципах досягнення взаємної згоди.
          </p>
          <p>
          <div style={{display:"flex",justifyContent:"center",flexDirection:"row",flexWrap:"wrap",marginTop:"10px"}}>
           
            <div style={{flexShrink:"1",width:"auto",}}>
                <a href="https://pumb.ua" style={{borderBottom:"0px"}} target="_blank">
                    <img src={pumbLogo} style={{height:"30px",width:"auto",paddingLeft:"10px"}} alt="mastercard branding logo"/>
                   
                </a>
            </div>
            <div style={{flexShrink:"1",width:"auto",paddingLeft:"5px"}}>
              Наш партнер в Україні.
           </div>
          </div>
          </p>
          <p>
            <div style={{display:"flex",justifyContent:"center",flexDirection:"row",flexWrap:"wrap",marginTop:"10px"}}>
            <div style={{flexShrink:"1",width:"auto",height:"30px"}}>
                <img src={visaLogo} style={{height:"25px",width:"auto",paddingRight:"12px"}} alt="visa branding logo"/>
            </div>
            <div style={{flexShrink:"1",borderLeft:"2px solid rgba(255,255,255,0.5)", height:"22px",marginTop:"3px"}}></div>
            <div style={{flexShrink:"1",width:"auto",}}>
                <img src={masterLogo} style={{height:"30px",width:"auto",paddingLeft:"10px",paddingRight:"10px"}} alt="mastercard branding logo"/>
            </div>
            </div>
          </p>
          <p>
            TRUSTA LLC., код ЄДРПОУ 43526873
            <br/>
            TRUSTA - онлайн-сервіс безпечних завдатків №1
          </p>

          
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', width: '100%' }}
        >
          <h2 className="major">Контакти</h2>
          <p>
            Відділ обслуговування клієнтів : <a href="mailto:support@trusta-pay.com">support@trusta-pay.com</a>
          </p>
          <p>
          З питань співпраці : <a href="mailto:office@trusta-pay.com">office@trusta-pay.com</a>
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
